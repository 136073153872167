var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"retouren"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[_c('v-card',{},[(_vm.selected_customer_info)?_c('v-card-title',[_vm._v(_vm._s(_vm.selected_customer_info.customer.name)+" retouren "),_c('v-spacer'),_c('v-btn',{attrs:{"to":{
                name: 'retour_upload',
                params: {
                  customerId: _vm.customerId,
                },
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_upward")]),_vm._v(" Retour uploaden ")],1)],1):_vm._e(),_c('v-data-table',{ref:"dataTableOverview",staticClass:"elevation-1",attrs:{"loading":_vm.sponsor_retouren === null,"no-data-text":"Geen retouren gevonden","loading-text":"Bezig met laden..","headers":_vm.tableHeaders,"items":_vm.tableItems,"items-per-page":50,"footer-props":{
              itemsPerPageOptions: [10, 50, 100, 200],
            }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","href":_vm.downloadLinkForItem(item)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_downward")]),_vm._v(" download ")],1)]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('strong',[_vm._v("Totaal op pagina")])]),_c('td',[_vm._v(_vm._s(_vm.totalOnPage))]),_c('td')]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('strong',[_vm._v("Totaal in selectie")])]),_c('td',[_vm._v(_vm._s(_vm.totalInSelection))]),_c('td')])])],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }