<template>
  <div class="retouren">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title v-if="selected_customer_info"
              >{{ selected_customer_info.customer.name }} retouren
              <v-spacer></v-spacer>
              <v-btn
                :to="{
                  name: 'retour_upload',
                  params: {
                    customerId: customerId,
                  },
                }"
              >
                <v-icon left>arrow_upward</v-icon>
                Retour uploaden
              </v-btn></v-card-title
            >

            <v-data-table
              ref="dataTableOverview"
              :loading="sponsor_retouren === null"
              no-data-text="Geen retouren gevonden"
              loading-text="Bezig met laden.."
              :headers="tableHeaders"
              :items="tableItems"
              :items-per-page="50"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 200],
              }"
            >
              <template slot="body.append">
                <tr>
                  <td colspan="3"><strong>Totaal op pagina</strong></td>
                  <td>{{ totalOnPage }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3"><strong>Totaal in selectie</strong></td>
                  <td>{{ totalInSelection }}</td>
                  <td></td>
                </tr>
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn small :href="downloadLinkForItem(item)">
                  <v-icon left>arrow_downward</v-icon> download
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomerPage from "@/mixins/customerpage";

export default {
  name: "Retouren",
  mixins: [CustomerPage],
  data() {
    return {
      sponsor_retouren: null,
    };
  },
  computed: {
    totalOnPage() {
      if (this.tableItems && this.$refs.dataTableOverview) {
        return this.$refs.dataTableOverview._data.internalCurrentItems.reduce(
          (a, b) => a + b.num_lines,
          0
        );
      }
      return "x";
    },
    totalInSelection() {
      return this.tableItems.reduce((a, b) => a + b.num_lines, 0);
    },
    tableItems() {
      if (!this.sponsor_retouren) {
        return [];
      }
      return this.sponsor_retouren.map((e) => {
        return {
          id: e.id,
          added: e.added,
          num_lines: e.num_lines,
          sponsor: e.sponsor.name,
        };
      });
    },
    tableHeaders() {
      return [
        {
          text: "#",
          value: "id",
        },
        {
          text: "Datum",
          value: "added",
        },
        {
          text: "Sponsor",
          value: "sponsor",
        },
        {
          text: "Aantal regels",
          value: "num_lines",
        },
        {
          text: "",
          value: "action",
          align: "end",
        },
      ];
    },
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      b.push({
        disabled: true,
        text: "Retouren",
      });

      return b;
    },
  },
  mounted() {
    // load exports
    this.axios
      .get("customer/" + this.customerId + "/returnfile")
      .then((res) => {
        this.sponsor_retouren = res.data.returnfiles;
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          // sponsor not found
          this.$router.push({
            name: "customer",
            params: {
              customerId: this.customerId,
            },
          });
        }
      });
  },
  methods: {
    downloadLinkForItem(item) {
      return (
        this.axios.defaults.baseURL +
        "customer/" +
        this.customerId +
        "/returnfile/" +
        item.id +
        "/download"
      );
    },
  },
};
</script>
